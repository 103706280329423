/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
html {
    --alertFontSize: 14px;
    --alertBorderRadius: 10px;
    --alertPadding: 10px 14px;

    //types
    --alertSuccessBackground: rgba(245, 255, 250, .95);
    --alertSuccessColor: #147447;
    --alertSuccessBorder: #caf3da;

    --alertWarningBackground: rgba(255, 251, 245, .95);
    --alertWarningColor: #a45a11;
    --alertWarningBorder: #f3dfca;

    --alertErrorBackground: rgba(255, 243, 243,.95);
    --alertErrorColor: #E23535;
    --alertErrorBorder: #eccccc;
}
//Arrow input number
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
//Arrow input number
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.cdk-overlay-pane {
    position: absolute;
    pointer-events: auto;
    box-sizing: border-box;
    z-index: 1000;
    display: flex;
    max-width: 100%;
    width: auto !important;
    max-height: 100%;
}

.alert {
    border-width: 0 0 var(--border-width);
    border-radius: 0;
    margin: 0;

    .btn-close, .btn-close:focus {
        box-shadow: none;
        outline: none;
    }

    &:last-of-type {
        border-bottom-width: 2px;
    }

    &.alert-primary {
        background-color: var(--alert-primary-bg);
        border-color: var(--alert-primary-border-color);
        color: var(--alert-primary-color);
    }
    &.alert-secondary {
        background-color: var(--alert-secondary-bg);
        border-color: var(--alert-secondary-border-color);
        color: var(--alert-secondary-color);
    }
    &.alert-success {
        background-color: var(--alert-success-bg);
        border-color: var(--alert-success-border-color);
        color: var(--alert-success-color);
    }
    &.alert-info {
        background-color: var(--alert-info-bg);
        border-color: var(--alert-info-border-color);
        color: var(--alert-info-color);
    }
    &.alert-warning {
        background-color: var(--alert-warning-bg);
        border-color: var(--alert-warning-border-color);
        color: var(--alert-warning-color);
    }
    &.alert-danger {
        background-color: var(--alert-danger-bg);
        border-color: var(--alert-danger-border-color);
        color: var(--alert-danger-color);
    }
    &.alert-light {
        background-color: var(--alert-light-bg);
        border-color: var(--alert-light-border-color);
        color: var(--alert-light-color);
    }
    &.alert-dark {
        background-color: var(--alert-dark-bg);
        border-color: var(--alert-dark-border-color);
        color: var(--alert-dark-color);
    }
}
mk-alert-center {
    width: 100%;

    & > mk-container {
        align-items: center;
    }
}


// Compact layout override
compact-layout {
    fuse-vertical-navigation.fuse-vertical-navigation-appearance-compact .fuse-vertical-navigation-wrapper,
    fuse-vertical-navigation.fuse-vertical-navigation-appearance-compact fuse-vertical-navigation-aside-item {
        background-color: var(--cl-dark-100);
    }
}

// Taiga UI - tabs
tui-tabs {
    button {
        &._active {
            border-bottom: 2px solid var(--cl-green);
            color: var(--cl-dark-200) !important;
        }
    }
}

// Material - bottom sheet
.cdk-overlay-container mat-bottom-sheet-container {
    border-radius: 12px 12px 0 0;
    overflow: hidden;
    padding: 0;
    min-width: 45vw;
}

// Material - multiple select
.optionDetailContainer {
    display: flex;
    flex-direction: column;
    padding: 10px 0;

    .titleOption {
        font-weight: 500;
    }

    p {
        font-size: 12px;
        color: var(--cl-dark-400);
        line-height: 15px;
    }
}

.mat-datepicker-content {
    box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
    background-color: #fff;
    color: #000000de
}

.mat-calendar-body-selected {
    background-color: var(--cl-green) !important;
    color: white !important;
}

.mat-calendar-body-cell-content {
    border: none !important;
}

.ngx-mat-timepicker .ngx-mat-timepicker-table .ngx-mat-timepicker-tbody tr td .mat-mdc-form-field {
    width: 80px !important;
    max-width:150px !important;
    margin-top : 20px !important;
}

.ngx-mat-timepicker form .ngx-mat-timepicker-table .ngx-mat-timepicker-tbody tr td.ngx-mat-timepicker-spacer {
    width: 30px !important;
}

@media (hover: hover){
    .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
        background-color: rgba(169, 168, 0, 0.4) !important;
    }
}

.mat-calendar-body-active {
    .mat-calendar-body-today {
        background-color: rgba(169, 168, 0, 0.4) !important;
    }
}

.mat-mdc-progress-spinner circle {
    stroke: var(--cl-green);
}

// override progress bar color
.mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
    background-color: var(--cl-green-light) !important;
}
.mdc-linear-progress__bar-inner {
    border-color: var(--cl-green) !important;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
    padding: 0 !important;
}

// override form field border color
.mat-mdc-form-field.mat-form-field-appearance-fill.mat-focused:not(.mat-form-field-invalid) .mat-mdc-text-field-wrapper {
    --tw-border-opacity: 1 !important;
    border-color: var(--cl-green) !important;
}

.mat-mdc-form-field.mat-focused .mat-mdc-select:not(.mat-mdc-select-invalid) .mat-mdc-select-arrow {
    color: var(--cl-green)!important;
}

.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
    color: var(--cl-green)!important;
}

// override checkbox color
.mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked::after, .mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-indeterminate::after {
    color: var(--cl-green)!important;
}
.mat-pseudo-checkbox-full.mat-pseudo-checkbox-checked, .mat-pseudo-checkbox-full.mat-pseudo-checkbox-indeterminate {
    background-color: var(--cl-green)!important;
}

.mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background, .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background, .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
    border-color: var(--cl-green)!important;
    background-color: var(--cl-green)!important;
}

// override form field select arrow color
.mat-mdc-form-field.mat-focused .mat-mdc-select:not(.mat-mdc-select-invalid) .mat-mdc-select-arrow {
    color: var(--cl-green)!important;
}

// override form field caret color
.mdc-text-field--filled:not(.mdc-text-field--invalid):not(.mdc-text-field--disabled) .mdc-text-field__input {
    caret-color: var(--cl-green) !important;
}

// override form field text color
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-floating-label {
    color: var(--cl-dark-200) !important;
}

//.mdc-dialog--open .mdc-dialog__container {
//    height: 80vh;
//    opacity: 1;
//}

.customHeaderMenuButton {
    float: left;
    margin: 0 0 0 3px;
}

.customHeaderLabel {
    float: left;
    margin: 0 0 0 3px;
}

.customSortDownLabel {
    float: left;
    margin: 0 0 0 3px;
}

.customSortUpLabel {
    float: left;
    margin: 0;
}

.customSortRemoveLabel {
    float: left;
    margin: 0 0 0 3px;
    font-size: 11px;
}

#buttonOpenCalculation {
    float: left;
    margin: 0 0 0 3px;
}

.active {
    color: cornflowerblue;
}
.mat-mdc-dialog-container .mdc-dialog__surface {
    padding: 0 !important;
}

.mat-mdc-form-field.mat-form-field-appearance-fill.mat-focused:not(.mat-form-field-invalid) .mat-mdc-text-field-wrapper {
    --tw-border-opacity: 1 !important;
    border-color: var(--cl-green) !important;
}

.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
    color: var(--cl-green)!important;
}

.mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked::after, .mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-indeterminate::after {
    color: var(--cl-green)!important;
}

.mat-pseudo-checkbox-full.mat-pseudo-checkbox-checked, .mat-pseudo-checkbox-full.mat-pseudo-checkbox-indeterminate {
    background-color: var(--cl-green)!important;
}

.mat-mdc-form-field.mat-focused .mat-mdc-select-arrow {
    color: var(--cl-green) !important;
}

.mat-datepicker-toggle-active {
    color: var(--cl-green) !important;
}

.mdc-checkbox {
    margin-left: 5px !important;
}

.mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background, .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background, .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate="true"]:enabled ~ .mdc-checkbox__background {
    border-color: var(--cl-green) !important ;
    background-color: var(--cl-green) !important;
}


.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle {
    border-color: var(--cl-green) !important;
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled + .mdc-radio__background .mdc-radio__inner-circle {
    border-color: var(--cl-green) !important;
}

.mat-mdc-optgroup-label {
    padding-left: 5px !important;
}

.mat-mdc-option {
    padding-left: 50px !important;
}

