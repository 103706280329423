@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-quartz.css';

ag-grid-angular.ag-calia , .ag-theme-quartz {
    height: 100%;
    width: 100%;
    flex: 1 1 0;
    --ag-header-background-color: var(--cl-green-light);
    --ag-active-color: var(--cl-green);


    .ag-header-row {
        color: var(--cl-dark-200);
    }

    .ag-row {
        color: var(--cl-dark-200);
    }

    .cellLink {
        color: var(--cl-dark-300);
        padding: 4px 10px;
        font-size: 13px;
        border-radius: 6px;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 1px;
        border: 1px solid var(--cl-dark-500);
        background-color: transparent;
        transition: .4s;
    }

    .cellDataEntry {
        z-index: 1;

        &:after {
            content: '';
            position: absolute;
            top: 5px;
            left: 5px;
            width: calc(100% - 10px);
            height: calc(100% - 10px);
            background-color: #FAFAF2;
            border: 1px solid #e6e6dd;
            opacity: .8;
            border-radius: 4px;
            z-index: -1;
        }
    }

    .selectContainerCell, .selectContainerCell:focus-within {
        padding: 0;
        border: 0!important;
    }

    // Top row used as form
    .ag-floating-top-container {
        .ag-row-pinned  {
            background-color: #e3e3cc;
        }
        .editableCell, .selectEditorCell {
            overflow: visible;
            border: none!important;
            outline: none!important;

            input, input:focus {
                border: none!important;
                outline: none!important;
                box-shadow: none!important;
            }

            &:after, &:after {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                left: 2px;
                right: 3px;
                border: 1px solid #9ea3b0;
                border-radius: 6px;
                pointer-events: none;
            }
        }
    }
    .ag-checkbox-input-wrapper.ag-checked::after {
        color: var(--cl-green);
    }

    .ag-row-loading {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .ag-cell-editable .ag-cell-wrapper {
        max-height: 100px;
        overflow-y: auto;
    }

    .ag-cell-wrap-text {
        line-height: 1.8;
        padding: 5px;
    }
}
