/* ----------------------------------------------------------------------------------------------------- */
/*  @ Main Tailwind file for injecting utilities.
/* ----------------------------------------------------------------------------------------------------- */
@tailwind utilities;

@layer utilities {
    .bg-default {
        background-color: var(--cl-green-light);
    }

    .font-extrabold {
        color: var(--cl-dark-200);
    }
}
