html {
    --cl-green: #A9A800;
    --cl-green-light: #FAFAF2;

    --cl-dark-100: #2D383E;
    --cl-dark-200: #444E5E;
    --cl-dark-300: #5B6472;
    --cl-dark-400: #64748B;
    --cl-dark-500: #CBD5E1;
    --cl-dark-550: #dbe5f0;
    --cl-dark-600: #eff3f9;
    --cl-dark-700: #f5f7f8;

    --cl-red: #E11D48;
    --cl-red-midlight: #fdd2dc;
    --cl-red-light: #fbe9ed;

    --cl-blue: #1d72e1;
    --cl-blue-midlight: #d2f0fd;
    --cl-blue-light: #e9f2fb;

    .bg-cl-dark-100 {
        background: var(--cl-dark-100);
    }

    .color-cl-dark-300 {
        color: var(--cl-dark-300);
    }

    .color-cl-green {
        color: var(--cl-green);
    }
}
